

import React, { useState, useEffect, useRef } from 'react'
import { Widget, addResponseMessage, addUserMessage, toggleWidget, toggleMsgLoader,renderCustomComponent, deleteMessages, toggleInputDisabled } from 'react-chat-widget'

import 'react-chat-widget/lib/styles.css'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { withStyles } from '@material-ui/core/styles'

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'


// translations stuff (see i18n.js and index.js)
import { useTranslation } from 'react-i18next'

// app tour
import Joyride from 'react-joyride'

//Audio recorder
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import 'audio-react-recorder/dist/index.css'

//FileSystem
import fs from 'fs'


//Device detector
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isIOS,
  isChrome
} from "react-device-detect";

const striptags = require('striptags')

//Neelo blue switch
/*const ColoredSwitch = withStyles({
  switchBase: {
    color: '#009dd5',
    '&$checked': {
      color: '#009dd5',
    },
    '&$checked + $track': {
      backgroundColor: '#009dd5',
    },
  },
  checked: {},
  track: {},
})(Switch)*/

//bcc orenge switch
const ColoredSwitch = withStyles({
  switchBase: {
    color: '#2ed9c3',
    '&$checked': {
      color: '#2ed9c3',
    },
    '&$checked + $track': {
      backgroundColor: '#2ed9c3',
    },
  },
  checked: {},
  track: {},
})(Switch)

const App = () => {

  // this makes the app fullscreen on any click interaction
  /*useEffect(() => {
    document.addEventListener("click", function() {
      var el = document.documentElement,
        rfs = el.requestFullscreen
          || el.webkitRequestFullScreen
          || el.mozRequestFullScreen
          || el.msRequestFullscreen 
      ;

      rfs.call(el);
  });
  }, [])*/

  // translations stuff init
  const { t, i18n } = useTranslation()
  // or const [t, i18n] = useTranslation()

  const [untilTimeout, setUntilTimeout] = useState(280) // timeout is 5 minutes (300 sec)
  const untilTimeoutIntervalRef = useRef(null)

  useEffect(() => {
    if (untilTimeout <= 0) {
      window.clearInterval(untilTimeoutIntervalRef.current)
      if (untilTimeoutIntervalRef.current !== null)
        untilTimeoutIntervalRef.current = null
      console.warn('The session is gone, reload the page to start a new chat')
    }/* else {
      console.log(`${untilTimeout}s. until timeout`)
    }*/
  }, [untilTimeout])

  // write effect
  const timerRef = useRef()
  const [subtitle, setSubtitle] = useState('')
  const subtitleRef = useRef(0)

  // conversation data
  const [uuid, setUuid] = useState()

  //Language info
  const languageDetector = (lng) => {
    //console.log(i18n.language)
    // this function will set all variants of a language to a single var
    if (i18n.language.startsWith('en')) {
      return 'en'
    } else  if (i18n.language.startsWith('de')) {
      return 'de'
    } else if (i18n.language.startsWith('fr')) {
      return 'fr'
    } else if (i18n.language.startsWith('eu')) {
      return 'eu'
    } else if (i18n.language.startsWith('es')) {
      return 'es'
    } else {
      // default detect language
      return 'detect'
    }
  }

  //For languages stuff
  const [language, setLanguage] = useState(languageDetector())
  const [lastLanguage, setLastLanguage] = useState(languageDetector())

    // translates out languages to web available languages
  const getLanguageSlug = () => {
    switch (language) {
      case 'eu':
        console.warn('This language has no transcription capabilities, defaulting to english...')
        return 'en-US'
      case 'fr':
        return 'fr-FR'
      case 'de':
        return 'de-DE'
      case 'es':
        return 'es-ES'
      case 'en':
      default:
        return 'en-US'
    }
  }

  //For audio synthesis stuff (text to speech)
  const [audioDataString, setAudioDataString] = useState('')
  const audioElementRef = useRef()

  //For silence (speaker) button control
  const [speak, setSpeak] = useState(true)

  ///For microphone button control and button style depending on device
  let buttonSize
  let buttonMargin
  buttonSize = '150px'
  buttonMargin = '200px'
 

  const [micAnimation, setMicAnimation] = useState({height: buttonSize, width: buttonSize, marginTop: buttonMargin, backgroundImage: "url('/mic_button_static_bcc.png')", backgroundSize: 'cover', backgroundPosition: 'center'})

  //For showing the chirimbolo when Neelo is speaking
  const [showChrimbolo, setShowChrimbolo] = useState(false)

  // tour stuff
  const [run, setRun] = useState(false)

  
  useEffect(() => {
    /*const writeEffect = () => {
      try {
        deleteMessages(10000, 'first-phrase')
      } catch(err) {
        console.log(err)
      }
      // Restart subtitles
      setSubtitle('')
      subtitleRef.current = 0
      // check if timer exists and remove
      if (timerRef.current !== undefined || timerRef.current !== null) {
        window.clearInterval(timerRef.current)
      }
      // write letters one by one
      window.setTimeout(() => {
        timerRef.current = window.setInterval(() => {
          if (subtitleRef.current < t('The conversational travel assistant').length) {
            setSubtitle((prev) => prev.concat(t('The conversational travel assistant')[subtitleRef.current]))
            subtitleRef.current = subtitleRef.current + 1
          } else {
            window.clearInterval(timerRef.current)
            setRun(() => true)
          }
        }, 50)
      }, 400)
      addResponseMessage(t('Hello, I am Neelo'), 'first-phrase')
    }*/
    //addResponseMessage(/*t('Hello, I am Neelo')*/'Hola. Soy SUKAI, el asistente virtual del LABe.', 'first-phrase')
    //addResponseMessage(/*t('Hello, I am Neelo')*/'Puedes preguntarme lo que quieras acerca del restaurante.', 'first-phrase')

    renderCustomComponent((component) => {return (<div style={{backgroundColor: '#f4f7f9', borderRadius: '10px', padding: '15px', maxWidth: '400px', textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: 'Hola. Soy SUKAI, el asistente virtual del LABe.' }}></div>)}, {})
    renderCustomComponent((component) => {return (<div style={{backgroundColor: '#f4f7f9', borderRadius: '10px', padding: '15px', maxWidth: '400px', textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: 'Puedes preguntarme lo que quieras acerca del restaurante.' }}></div>)}, {})
    //i18n.changeLanguage(language)
    //writeEffect()
  }, [language])



  //Render the text as a chat
  useEffect(() => { // Update the document title using the browser API    
    //Function to create a watson conversation and the chat
    const createConversation = async () => {
      const json = await window.fetch('https://api.bcclabe.kevo.sh/chat-api/create-conversation/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          token: '2bb93d7eef110d0ea3176381a1acaa8dd47576906b296251abf036d13732953186a602ee1afcaa2f8aee4e9cbb2205127e2eca0e0b765e38472f42d185025512'
        })
      })
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw Error('Response from server is not OK')
        }
      })
      .catch(err => {
        console.error('Error sending message, see below log')
        console.error(err)
        return false
      })
      if (json !== false) {
        setUuid((prev) => json.uuid)
      }
    }
    // Create conversation an toggle widget only once

    //Trigger event when audio ends and hide the chirimbolo
    /*audioElementRef.current.addEventListener('ended', () => {
      setShowChrimbolo(false)
    })*/

    untilTimeoutIntervalRef.current = window.setInterval(() => {
      setUntilTimeout((prev) => (prev - 1))
    }, 1000)
    createConversation()
    toggleWidget()
  }, [])


 
  //Function that triggers everytime a user sends a message
  const handleNewUserMessage = async (newMessage) => {

    //Function to detect the language of a message
    /*const detectLanguage = async (message) => {
      //Function to detect a language
      const data = await window.fetch('https://api.neelo.kevo.sh/chat-api/detect-language/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          message: message
        })
      })
      .then(async (res) => {
        if (res.ok) {
          const json = await res.json()
          console.log(json)
          return json.languageDetected
        } else {
          throw Error(`Error response from the server : ${res.status}`)
        }
      })
      .catch((err) => {
        console.error('Error sending input to Chat Service!')
        console.log(err)
        return {}
      })
      console.log("Detected language: " + data)
      return data
    }*/

    const synthesize = async (text, languageCode) => {
      //Function to synthesize text
      const data = await window.fetch('https://api.bcclabe.kevo.sh/chat-api/synthesize/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: text,
          languageCode: languageCode 
        })
      })
      .then(async (res) => {
        if (res.ok) {
          console.log("return form synthesize: " + res.status)
          return res.arrayBuffer()
        } else {
          throw Error(`Error response from the server : ${res.status}`)
        }
      })
      .catch((err) => {
        console.error('Error sending input to Chat Service!')
        console.log(err)
        return {}
      })
      //console.log("Return from synthesize: " + data)
      return data
    }

    //Receive new message, toggle the message loader an disable input until a response is given
    console.log(`New message incomig! ${newMessage}`)
    toggleMsgLoader()
    toggleInputDisabled()

    //if detection is ativated and the message is not a number we detect the language of the sent language and set it as the last detected language
    /*let detectedLanguage
    if (isNaN(newMessage) && language === 'detect' && newMessage.split(" ").length >= 3) {
      detectedLanguage = await detectLanguage(newMessage)
      setLastLanguage((prev) => detectedLanguage)
      console.log("Detected language for '" + newMessage + "' : " + lastLanguage)
    } else {
      console.log("Number detected or no language detection activated")
      console.log("last selected language was: " + lastLanguage)
      detectedLanguage = lastLanguage
    }*/


    const json = await window.fetch('https://api.bcclabe.kevo.sh/chat-api/send-message/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        token: '2bb93d7eef110d0ea3176381a1acaa8dd47576906b296251abf036d13732953186a602ee1afcaa2f8aee4e9cbb2205127e2eca0e0b765e38472f42d185025512',
        uuid: uuid,
        language: language,
        //languageCode: detectedLanguage,
        text: newMessage
      })
    })
    .then(res => {
      if (res.ok) {
        // set the timeout time
        setUntilTimeout((prev) => 280)
        return res.json()
      } else {
        throw Error('Response from server is not OK')
      }
    })
    .catch(err => {
      console.error('Error sending message, see below log')
      console.error(err)
      return false
    })
    if (json !== false) {

      // get the audio synthesis buffer and then play it in the audio tag
      if(language !== 'detect' && speak){
        const audioData = await synthesize(striptags(json.text), language)
        const blob = new Blob([audioData], { type: "audio/wav" })
        const dataUrl = window.URL.createObjectURL(blob)
        setAudioDataString(dataUrl)
        audioElementRef.current.play()
        //setShowChrimbolo(true)
      }

      // writting indicator stop and enable user input, focus to user input 
      toggleMsgLoader()
      toggleInputDisabled()
      //document.getElementsByClassName('rcw-new-message')[0].focus()


      // add the message to the chat
      renderCustomComponent((component) => {return (<div style={{backgroundColor: '#f4f7f9', borderRadius: '10px', padding: '15px', maxWidth: '300px', textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: json.text }}></div>)}, {})

    } else {
      console.error('Error loading response from server, see above logs for more details')
      // stop the writting indicator
      toggleMsgLoader()
    }
  }

  //*********************************************************PARTE DE RECONOCIMIENTO DE VOZ SOLO CHROME***************************************************************
  
  const { resetTranscript, listening, finalTranscript } = useSpeechRecognition()
  // transcript, listening, interimTranscript

  //For showing the listening icon when user speaking
  const [listenIcon, setListenIcon] = useState(false)


  useEffect(() => { //send message when state of speech recognition changes
    if (finalTranscript !== '') {
      handleNewUserMessage(finalTranscript)
      addUserMessage(finalTranscript)
      resetTranscript()
    }
  }, [finalTranscript])

  const keyboardEventDown = (event) => {
    // event.persist();
    if (event.key === 'Control') {
      if (SpeechRecognition.browserSupportsSpeechRecognition()) {
        setListenIcon((prev) => true)
        SpeechRecognition.startListening({
          continuous: 'true',
          language: getLanguageSlug()
        })
      }
    }
  }


  const keyboardEventUp = async (event) => {
    // event.persist();
    if (event.key === 'Control') {
      if (SpeechRecognition.browserSupportsSpeechRecognition()) {
        SpeechRecognition.stopListening()
        setListenIcon((prev) => false)
      }
    }
  }

  //*****************************************************************************************************************************************************************

  //For showing the listening icon when user speaking
  //const [listenIcon, setListenIcon] = useState(false)
  //For audio record
  const [recordState, setRecordState] = useState(null)
  const [audioData, setAudioData] = useState(null) 

  //Function to stop neelo's audio
  const silenceNeelo = async (event) => {
    if (speak) {
      if (audioElementRef.current !== undefined) {
        audioElementRef.current.pause()
        //setShowChrimbolo(false)
      }
      setSpeak((prev) => false)
    } else {
      setSpeak((prev) => true)
    }
  }



  const mouseDownEvent = (event) => {
    // disable left mouse button
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      //setListenIcon((prev) => true)
      SpeechRecognition.startListening({
          continuous: 'true',
          language: getLanguageSlug()
        })
     setMicAnimation((prev) => ({height: buttonSize, width: buttonSize, marginTop: buttonMargin, backgroundImage: "url('/mic_button_animation_bcc.gif')", backgroundSize: 'cover', backgroundPosition: 'center'}))
    } else {
      console.warn('This browser will not work with speech functionalities')
    }
    //Para usar con grabadora
    //setMicAnimation((prev) => ({height: buttonSize, width: buttonSize, marginTop: buttonMargin, backgroundImage: "url('/mic_button_animation_bcc.gif')", backgroundSize: 'cover', backgroundPosition: 'center'}))
    //setRecordState((prev) => RecordState.START)
  }

  const mouseUpEvent = async (event) => {
    // event.persist();
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.stopListening()
      //setListenIcon((prev) => false)
     setMicAnimation((prev) => ({height: buttonSize, width: buttonSize, marginTop: buttonMargin, backgroundImage: "url('/mic_button_static_bcc.png')", backgroundSize: 'cover', backgroundPosition: 'center'}))
    }

    //Para usar con grabadora
    //setMicAnimation((prev) => ({height: buttonSize, width: buttonSize, marginTop: buttonMargin, backgroundImage: "url('/mic_button_static_bcc.png')", backgroundSize: 'cover', backgroundPosition: 'center'}))
    //setRecordState((prev) => RecordState.STOP)
  }

     /*const start = () => {
        setRecordState((prev) => RecordState.START)
     }

     const pause = () => {
        setRecordState((prev) => RecordState.PAUSE)
     }

     const stop = () => {
        setRecordState((prev) => RecordState.STOP)
     }*/

  const onStop = async (data) => {
        
        var fd = new FormData()
        fd.append('audio', data.blob)
        fd.append('enctype', 'multipart/formdata')

               
        const json =  await window.fetch('https://api.neelo.kevo.sh/chat-api/speech-to-text/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: fd
        })
        .then(res => {
          if (res.ok) {
            // set the timeout time
            setUntilTimeout((prev) => 280)
            return res.json()
          } else {
            throw Error('Response from server is not OK')
          }
        })
        .catch(err => {
          console.error('Error sending message, see below log')
          console.error(err)
          return false
        })

        if(json !== false && json.transcription.trim() !== ''){
          console.log(json.transcription)
          handleNewUserMessage(json.transcription)
          addUserMessage(json.transcription)        
       }
  }

     
  



  const ChatSubtitle = (props) => {
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <img style={{marginBottom: '-16px', width: 'auto', height: '35px', marginLeft: 'auto', marginRight: 'auto'}} src="/bcc_logo.png" alt="Kevo Neelo"/>
        {listenIcon && <img style={{marginTop: '8px', marginBottom: '-16px', width: 'auto', height: '36px', marginLeft: 'auto', marginRight: 'auto'}} src="/microphone_animation.gif" alt="Listening..."/>}
        {showChrimbolo && <img style={{marginTop: '8px', marginBottom: '-16px', width: 'auto', height: '36px', marginLeft: 'auto', marginRight: 'auto'}} src="/chirimbolo4.gif" alt="Speaking..."/>} 
      </div>
    )
  }

  return (
    <Grid container spacing={2}>
      <div className="write-message-step" style={{width: '100%', marginTop:'-105px', marginBottom: '-270px'}} /*onKeyDown={keyboardEventDown} onKeyUp={keyboardEventUp}*/>
        <Widget 
          autofocus={true} 
          /*senderPlaceHolder={t('Type a message...')}*/
          senderPlaceHolder={'Escribe o graba un mensaje...'} 
          showTimeStamp={false} 
          showCloseButton={false} 
          fullScreenMode={true} 
          title={''} 
          subtitle={<img style={{marginBottom: '-16px', width: 'auto', height: '35px', marginLeft: 'auto', marginRight: 'auto'}} src="/bcc_logo.png" alt="Kevo Neelo"/>} 
          handleNewUserMessage={handleNewUserMessage} />
      </div>
      {untilTimeout > 0
        ? <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
             {SpeechRecognition.browserSupportsSpeechRecognition() ? 
                <div>
                  <IconButton className="microphone_button" onContextMenu={(e)=> e.preventDefault()} 
                  onMouseDown={mouseDownEvent} 
                  onMouseUp={mouseUpEvent} 
                  onTouchStart={mouseDownEvent} 
                  onTouchEnd={mouseUpEvent} 
                  style={micAnimation}/>
                  <FormControlLabel
                    style={{display: 'flex', marginLeft: 'auto', marginRight: 'auto'}}
                    control={<ColoredSwitch /*style={{color: '#009dd5'}}*/ style={{color: '#2ed9c3'}} checked={speak} onChange={silenceNeelo} name="activate-tts" />}
                    label={<span style={{color: 'white'}}>{t('Voice mode')}</span>}
                  />
                </div>
                :
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '250px'}}>
                  {isIOS ? <span style={{color: 'white', textAlign: 'center', paddingLeft: '16px', paddingRight: '16px'}}>{'El reconocimiento de voz no esta disponible en iOS.'}</span> : <span style={{color: 'white', textAlign: 'center', paddingLeft: '16px', paddingRight: '16px'}}>{'El reconocimiento de voz solo está disponible en los navegadores Google Chrome y Edge en ordenadores.'}</span>}
                  <FormControlLabel
                      style={{display: 'flex', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}
                      control={<ColoredSwitch /*style={{color: '#009dd5'}}*/ style={{color: '#2ed9c3'}} checked={speak} onChange={silenceNeelo} name="activate-tts" />}
                      label={<span style={{color: 'white'}}>{t('Voice mode')}</span>}
                    />
                </div>
              }
            </div>
          : 
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
              <IconButton 
                onMouseDown={() => window.location.reload()}
                onTouchStart={() => window.location.reload()}
                style={{height: buttonSize, width: buttonSize, marginTop: buttonMargin, backgroundImage: "url('/reloadicon_bcc.png')", backgroundSize: 'cover', backgroundPosition: 'center'}}/>
                <span style={{color: 'white', textAlign: 'center', marginTop: '-30px', width:'60%'}}>{/*t('The session has gone, reload the page by clicking or touching the button above to start a new conversation')*/'La sesión ha expirado, recarga la página haciendo click o tocando el botón de arriba para iniciar una nueva conversación'}</span>
            </div>
           /*<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
              <IconButton 
                onMouseDown={() => window.location.reload()}
                onTouchStart={() => window.location.reload()}
                style={{height: '130px', width: '130px', backgroundImage: "url('/reloadicon.png')", backgroundPosition: 'center', backgroundColor: '#009dd5', backgroundRepeat: 'no-repeat', backgroundSize: '70%', display: 'flex', marginLeft: 'auto', marginRight: 'auto'}}/>
                <span style={{color: 'white', textAlign: 'center', marginTop: '16px'}}>{t('The session has gone, reload the page by clicking or touching the button above to start a new conversation')}</span>
            </div>*/}
      <audio controls ref={audioElementRef} src={audioDataString} style={{visibility: 'hidden'}}>Your browser seems to not work with audio</audio>      
      <div style={{visibility: 'hidden'}}>
      <AudioReactRecorder
        state={recordState}
        onStop={onStop}
        backgroundColor='rgb(255,255,255)'
      />
      {/*<audio
        id='audio'
        controls
        src={audioData ? audioData.url : null}
        ></audio>*/}
    </div>
  </Grid>
  )
}

export default App
