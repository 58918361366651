import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'The conversational travel assistant': 'The conversational travel assistant',
      'Hello, I am Neelo': 'Hello, I am Neelo',
      'Hello, I am <b>Neelo</b>': 'Hello, I am <b>Neelo</b>',
      '<b>Multiplatform chat integration</b> with Whatsapp, Telegram, Twitter, Facebook Messenger, plugins, web...': '<b>Multiplatform chat integration</b> with Whatsapp, Telegram, Twitter, Facebook Messenger, plugins, web...',
      'Voice communication in 5 languages and text communication in <b>over 100 languages.</b>': 'Voice communication in 5 languages and text communication in <b>over 100 languages.</b>',
      '<b>Inteligent Tourism System</b> backoffice integration.': '<b>Inteligent Tourism System</b> backoffice integration.',
      'Ad-hoc <b>workflow customization</b> and robotic process automation.': 'Ad-hoc <b>workflow customization</b> and robotic process automation.',
      'State-of-the-art artificial inteligence system with <b>next-gen cognitive capabilities.</b>': 'State-of-the-art artificial inteligence system with <b>next-gen cognitive capabilities.</b>',
      'Automatically detect language': 'Automatically detect language',
      'Type a message...': 'Type a message...',
      'powered by': 'powered by',
      'gatik garatua': '',
      'Welcome to Neelo! We will guide you through the application shortly and you will be able to test it in the end. Click next to continue...': 'Welcome to Neelo! We will guide you through the application shortly and you will be able to test it in the end. Click next to continue...',
      'You can select from this list clicking the flag icon of your preferred language. Click next to continue...': 'You can select from this list clicking the flag icon of your preferred language. Click next to continue...',
      'If your language is not shown in the flags you can select this option to let Neelo detect your language automatically, it will need at least 3 words to do it correctly. Click next to continue...': 'If your language is not shown in the flags you can select this option to let Neelo detect your language automatically, it will need at least 3 words to do it correctly. Click next to continue...',
      'Write to Neelo as you would with any other person, it is a cognitive system not a robot ;) Click last to start using the application...': 'Write to Neelo as you would with any other person, it is a cognitive system not a robot ;) Click last to start using the application...',
      'If your browser supports speech recognition (Google Chrome, Microsoft Edge...), you can hold down the the "Control" key to speak and send messages using the voice (for now this feature is only available in Spanish).': 'If your browser supports speech recognition (Google Chrome, Microsoft Edge...), you can hold down the the "Control" key to speak and send messages using the voice (for now this feature is only available in Spanish).',
      'Back': 'Back',
      'Close': 'Close',
      'Last': 'Last',
      'Next': 'Next',
      'Skip': 'Skip',
      'The session has gone, reload the page by clicking or touching the button above to start a new conversation': 'The session has gone, reload the page by clicking or touching the button above to start a new conversation',
      'Voice mode': 'Voice mode'
    }
  },
  fr: {
    translation: {
      'The conversational travel assistant': 'L\'assistant de voyage conversationnel',
      'Hello, I am Neelo': 'Bonjour, je suis Neelo',
      'Hello, I am <b>Neelo</b>': 'Bonjour, je suis <b>Neelo</b>',
      '<b>Multiplatform chat integration</b> with Whatsapp, Telegram, Twitter, Facebook Messenger, plugins, web...': '<b>Intégration de chat multiplateforme</b> avec Whatsapp, Telegram, Twitter, Facebook Messenger, plugins, web...',
      'Voice communication in 5 languages and text communication in <b>over 100 languages.</b>': 'Communication vocale en 5 langues et communication textuelle <b>dans plus de 100 langues.</b>',
      '<b>Inteligent Tourism System</b> backoffice integration.': '<b>Intelligent touristique système</b> et intégration du back-office.',
      'Ad-hoc <b>workflow customization</b> and robotic process automation.': '<b>Personnalisation ad-hoc du workflow</b> et automatisation des processus robotiques.',
      'State-of-the-art artificial inteligence system with <b>next-gen cognitive capabilities.</b>': 'Système d\'intelligence artificielle à la pointe de la technologie avec des <b>capacités cognitives de nouvelle génération.</b>',
      'Automatically detect language': 'Détection automatique de la langue',
      'Type a message...': 'Tapez un message ...',
      'powered by': 'développé par',
      'gatik garatua': '',
      'Welcome to Neelo! We will guide you through the application shortly and you will be able to test it in the end. Click next to continue...': 'Bienvenue à Neelo! Nous vous guiderons à travers l\'application sous peu et vous pourrez la tester à la fin. Cliquer sur Suivant pour continuer...',
      'You can select from this list clicking the flag icon of your preferred language. Click next to continue...': 'Vous pouvez sélectionner dans cette liste en cliquant sur l\'icône du drapeau de votre langue préférée. Cliquer sur Suivant pour continuer...',
      'If your language is not shown in the flags you can select this option to let Neelo detect your language automatically, it will need at least 3 words to do it correctly. Click next to continue...': 'Si votre langue n\'est pas affichée dans les drapeaux, vous pouvez sélectionner cette option pour laisser Neelo détecter votre langue automatiquement, il aura besoin d\'au moins 3 mots pour le faire correctement',
      'Write to Neelo as you would with any other person, it is a cognitive system not a robot ;) Click last to start using the application...': 'Écrivez à Neelo comme vous le feriez avec n\'importe quelle autre personne, c\'est un système cognitif pas un robot;) Cliquez en dernier pour commencer à utiliser l\'application ...',
      'If your browser supports speech recognition (Google Chrome, Microsoft Edge...), you can hold down the the "Control" key to speak and send messages using the voice (for now this feature is only available in Spanish).': 'Si votre navigateur prend en charge la reconnaissance vocale (Google Chrome, Microsoft Edge ...), vous pouvez maintenir la touche "Control" enfoncée pour parler et envoyer des messages en utilisant la voix (pour l\'instant cette fonctionnalité n\'est disponible qu\'en espagnol).',
      'Back': 'Retour',
      'Close': 'Proche',
      'Last': 'Terminer',
      'Next': 'Prochain',
      'Skip': 'Sauter',
      'The session has gone, reload the page by clicking or touching the button above to start a new conversation': 'La session est terminée, rechargez la page en cliquant ou en touchant le bouton ci-dessus pour démarrer une nouvelle conversation',
      'Voice mode': 'Mode vocal'
    }
  },
  de: {
    translation: {
      'The conversational travel assistant': 'Der Gesprächsassistent',
      'Hello, I am Neelo': 'Hallo, ich bin Neelo',
      'Hello, I am <b>Neelo</b>': 'Hallo, ich bin <b>Neelo</b>',
      '<b>Multiplatform chat integration</b> with Whatsapp, Telegram, Twitter, Facebook Messenger, plugins, web...': '<b>Multiplattform-Chat-Integration</b> mit WhatsApp, Telegram, Twitter, Facebook Messenger, Plugins, Web...',
      'Voice communication in 5 languages and text communication in <b>over 100 languages.</b>': 'Sprachkommunikation in 5 Sprachen und Textkommunikation in <b> über 100 Sprachen.</b>',
      '<b>Inteligent Tourism System</b> backoffice integration.': 'Backoffice-Integration des <b>intelligenten Touristensystems.</b>',
      'Ad-hoc <b>workflow customization</b> and robotic process automation.': '<b>Ad-hoc-Workflow-Anpassung</b> und Automatisierung von Roboterprozessen.',
      'State-of-the-art artificial inteligence system with <b>next-gen cognitive capabilities.</b>': '<b>Künstliches Intelligenzsystem auf dem neuesten Stand der Technik</b> mit kognitiven Fähigkeiten der nächsten Generation.',
      'Automatically detect language': 'Sprache automatisch erkennen',
      'Type a message...': 'Geben Sie eine Nachricht ein ...',
      'powered by': 'entwickelt von',
      'gatik garatua': '',
      'Welcome to Neelo! We will guide you through the application shortly and you will be able to test it in the end. Click next to continue...': 'Willkommen bei Neelo! Wir werden Sie in Kürze durch die Anwendung führen und Sie können sie am Ende testen. Klicke weiter um fortzufahren...',
      'You can select from this list clicking the flag icon of your preferred language. Click next to continue...': 'Sie können aus dieser Liste auswählen, indem Sie auf das Flaggensymbol Ihrer bevorzugten Sprache klicken. Klicke weiter um fortzufahren...',
      'If your language is not shown in the flags you can select this option to let Neelo detect your language automatically, it will need at least 3 words to do it correctly. Click next to continue...': 'Wenn Ihre Sprache nicht in den Flags angezeigt wird, können Sie diese Option auswählen, damit Neelo Ihre Sprache automatisch erkennt. Es werden mindestens 3 Wörter benötigt, um dies korrekt zu tun',
      'Write to Neelo as you would with any other person, it is a cognitive system not a robot ;) Click last to start using the application...': 'Schreiben Sie an Neelo wie an jede andere Person, es ist ein kognitives System, kein Roboter;) Klicken Sie zuletzt, um die Anwendung zu starten ...',
      'If your browser supports speech recognition (Google Chrome, Microsoft Edge...), you can hold down the the "Control" key to speak and send messages using the voice (for now this feature is only available in Spanish).': 'Wenn Ihr Browser die Spracherkennung unterstützt (Google Chrome, Microsoft Edge ...), können Sie die Taste "Control" gedrückt halten, um mit der Sprache zu sprechen und Nachrichten zu senden (diese Funktion ist derzeit nur auf Spanisch verfügbar.',
      'Back': 'Zurück',
      'Close': 'Schließen',
      'Last': 'Letzte',
      'Next': 'Nächster',
      'Skip': 'Überspringen',
      'The session has gone, reload the page by clicking or touching the button above to start a new conversation': 'Die Sitzung ist beendet. Laden Sie die Seite neu, indem Sie auf die Schaltfläche oben klicken oder sie berühren, um eine neue Konversation zu starten',
      'Voice responses': 'Sprachmodus'
    }
  },
  eu: {
    translation: {
      'The conversational travel assistant': 'Elkarrizketa bidezko bidaia laguntzailea',
      'Hello, I am Neelo': 'Kaixo, Neelo naiz',
      'Hello, I am <b>Neelo</b>': 'Kaixo, <b>Neelo</b> naiz',
      '<b>Multiplatform chat integration</b> with Whatsapp, Telegram, Twitter, Facebook Messenger, plugins, web...': '<b>Plataforma anitzekin txat integrazioa</b> WhatsApp, Telegramm, Twitter, Facebook Messenger, Plugins, Web... bitartez',
      'Voice communication in 5 languages and text communication in <b>over 100 languages.</b>': 'Ahots bidezko komunikaziooa 5 hizkuntzatan eta textu bidezko komunikazioa <b>100 hizkuntza baino gehiagotan.</b>',
      '<b>Inteligent Tourism System</b> backoffice integration.': '<b>Adimendun turismo sistema,</b> backoffice-rako integrazioa.',
      'Ad-hoc <b>workflow customization</b> and robotic process automation.': '<b>Workflow-aren ad-hoc personalizazioa</b> eta prozesuen automatizazio robotikoa.',
      'State-of-the-art artificial inteligence system with <b>next-gen cognitive capabilities.</b>': 'Azken generazioko adimen sistema eta <b>next-gen gaitasun kognitiboak.',
      'Automatically detect language': 'Hizkuntza automatikoki antzeman',
      'Type a message...': 'Idatzi mezu bat...',
      'powered by': '',
      'gatik garatua': 'gatik garatua',
      'Welcome to Neelo! We will guide you through the application shortly and you will be able to test it in the end. Click next to continue...': 'Ongi etorri Neelora! Aplikazioaren erakustaldi motz bat egingo dugu eta amaieran hau erabiltzeko aukera izango duzu. Sakatu hurrengoa jarraitzeko...',
      'You can select from this list clicking the flag icon of your preferred language. Click next to continue...': 'Zure hizkuntza aukeratu dezakezu zure hizkuntza gogokoenaren zerrenda honetako banderaren gainean sakatuz. Sakatu hurrengoa jarraitzeko...',
      'If your language is not shown in the flags you can select this option to let Neelo detect your language automatically, it will need at least 3 words to do it correctly. Click next to continue...': 'Zure hizkuntza gogokoena zerrenda honetan agertzen ez bada sakatu botoi hau eta Neelok zure hizkuntza hautemango du automatikoki, gutxienez 3 hitz beharko ditu ondo egiteko. Sakatu hurrengoa jarraitzeko...',
      'Write to Neelo as you would with any other person, it is a cognitive system not a robot ;) Click last to start using the application...': 'Idatzi Neelori beste pertsonekin hitz egingo zenuen bezalaxe, sistema adimendu bat da ez errobot bat ;) Sakatu amaitu aplikazioa erabiltzen hasteko...',
      'If your browser supports speech recognition (Google Chrome, Microsoft Edge...), you can hold down the the "Control" key to speak and send messages using the voice (for now this feature is only available in Spanish).': 'Zure arakatzaileak ahots-hautematea onartzen badu (Google Chrome, Microsoft Edge ...), "Control" tekla sakatuta eduki dezakezu ahotsa erabiliz mezuak bidaltzeko (oraingoz funtzio hau gaztelaniaz bakarrik dago erabilgarri).',
      'Back': 'Atzera',
      'Close': 'Itxi',
      'Last': 'Amaitu',
      'Next': 'Hurrengoa',
      'Skip': 'Irten',
      'The session has gone, reload the page by clicking or touching the button above to start a new conversation': 'Saioa iraungitu da, goiko botoia sakatu orrialdea birkargatzeko eta elkarrizketa berri bat hasteko',
      'Voice mode': 'Ahots modua'
    }
  },
  es: {
    translation: {
      'The conversational travel assistant': 'El asistente de viaje conversacional',
      'Hello, I am Neelo': 'Hola, soy Neelo',
      'Hello, I am <b>Neelo</b>': 'Hola, soy <b>Neelo</b>',
      '<b>Multiplatform chat integration</b> with Whatsapp, Telegram, Twitter, Facebook Messenger, plugins, web...': '<b>Integración de chat multiplataforma</b> vía Whatsapp, Telegram, Twitter, Facebook Messenger, plugins, web...',
      'Voice communication in 5 languages and text communication in <b>over 100 languages.</b>': 'Comunicación por voz en 5 idiomas y comunicación por texto <b>en más de 100 idiomas.</b>',
      '<b>Inteligent Tourism System</b> backoffice integration.': '<b>Sistema de turismo inteligente</b> con integración en sistemas backoffice.',
      'Ad-hoc <b>workflow customization</b> and robotic process automation.': '<b>Personalización ad-hoc del flujo de trabajo</b> y automatización robótica de procesos.',
      'State-of-the-art artificial inteligence system with <b>next-gen cognitive capabilities.</b>': 'Sistema de inteligencia artificial con <b>habilidades cognitivas de última generación.</b>',
      'Automatically detect language': 'Autodetectar idioma',
      'Type a message...': 'Escribe un mensaje...',
      'powered by': 'desarrollado por',
      'gatik garatua': '',
      'Welcome to Neelo! We will guide you through the application shortly and you will be able to test it in the end. Click next to continue...': '¡Bienvenido/a a Neelo! Te guiaremos a través de la aplicación brevemente y podrá probarla al final. Haz clic en siguiente para continuar...',
      'You can select from this list clicking the flag icon of your preferred language. Click next to continue...': 'Puedes seleccionar tu idioma haciendo clic en una bandera de esta lista. Haz clic en siguiente para continuar...',
      'If your language is not shown in the flags you can select this option to let Neelo detect your language automatically, it will need at least 3 words to do it correctly. Click next to continue...': 'Si tu idioma no se muestra en las banderas, puedes seleccionar esta opción para que Neelo detecte tu idioma automáticamente, necesitará al menos 3 palabras para hacerlo correctamente. Haz clic en Siguiente para continuar...',
      'Write to Neelo as you would with any other person, it is a cognitive system not a robot ;) Click last to start using the application...': 'Escribe a Neelo como lo harías con cualquier otra persona, es un sistema cognitivo no un robot;) Haz clic en finalizar para empezar a usar la aplicación ...',
      'If your browser supports speech recognition (Google Chrome, Microsoft Edge...), you can hold down the the "Control" key to speak and send messages using the voice (for now this feature is only available in Spanish).': 'Si tu navegador es compatible con el reconocimiento de voz (Google Chrome, Microsoft Edge ...), puedes mantener presionada la tecla "Control" para hablar y enviar mensajes usando la voz (por ahora, esta función solo está disponible en español).',
      'Back': 'Anterior',
      'Close': 'Cerrar',
      'Last': 'Finalizar',
      'Next': 'Siguiente',
      'Skip': 'Salir',
      'The session has gone, reload the page by clicking or touching the button above to start a new conversation': 'La sesión ha caducado, recarga la página pulsando el botón de arriba para comenzar una nueva conversación',
      'Voice mode': 'Modo voz'
    }
  },
  detect: {
    translation: {
      'gatik garatua': ''
    }
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: {
      locales: ['en', 'de', 'es', 'fr', 'eu'],
      fallbackLng: ['en', 'de', 'es', 'fr', 'eu'],
      // order and from where user language should be detected
      order: ['navigator'], //'localStorage', 'cookie', 
      //caches: ['localStorage', 'cookie'],
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    fallbackLng: 'en',
    debug: false,
  })

  export default i18n